<template>
  <div class="w1092" v-if="list">
    <div class="board">
      <ul class="boardw first">
        <li class="w115">{{$t('front.board.check')}}</li>
        <li class="w115">{{$t('front.board.status')}}</li>
        <li class="w115">{{$t('front.board.type')}}</li>
        <li class="w430">{{$t('front.board.title')}}</li>
        <li class="w140">{{$t('front.board.regDate')}}</li>
        <li class="w115">{{$t('front.board.delete')}}</li>
      </ul>
      <template v-if="list.length > 0">
        <template v-for="item in list" v-bind:key="item.boardIdx">
          <ul class="boardw">
            <li class="w115"><span class="ocmsg" :class="item.isReadAdmin === 'Y' ? 'openmsg': 'closemsg'"></span></li>
            <li class="w115">
              <span v-if="item.status === 'ANSWER'" class="blc nbicon wtblbg">{{$t('front.qnaStatus.answer')}}</span>
              <span v-if="item.status === 'WAIT'" class="nbicon bkblbg">{{$t('front.qnaStatus.wait')}}</span>
            </li>
            <li class="w115">{{$t('front.qnaTypeCategory.' + item.faqType)}}</li>
            <li class="w430 textleft" @click.prevent="onClickRead(item.boardIdx)">
              <a href="#" >
                {{item.title}}
                <span class="newicon rdbg ml5">N</span>
              </a>
            </li>
            <li class="w140">{{dateFormatForBoard(item.regDate)}}</li>
            <li class="w115"><span class="nbicon bgrbg" @click.prevent="onClickRemove(item.boardIdx)">{{$t('front.board.delete')}}</span></li>
          </ul>
        </template>
      </template>
      <template v-else>
        <ul class="boardw">
          <li class="">{{$t('front.board.noAsk')}}</li>
        </ul>
      </template>

<!--      <ul class="boardw wtblbg">-->
<!--        <li class="w115"><span class="ocmsg closemsg"></span></li>-->
<!--        <li class="w115"><span class="nbicon bkblbg">답변대기</span></li>-->
<!--        <li class="w115">계좌문의</li>-->
<!--        <li class="w430 textleft" @click.prevent="onClickRead()"><a href="#" >은행계좌정보<span class="newicon rdbg ml5">N</span></a></li>-->
<!--        <li class="w140">2021.06.29 15:36</li>-->
<!--        <li class="w115"><span class="nbicon bgrbg">삭제</span></li>-->
<!--      </ul>-->
<!--      <ul class="boardw">-->
<!--        <li class="w115"><span class="ocmsg openmsg"></span></li>-->
<!--        <li class="w115"><span class="blc nbicon wtblbg">답변완료</span></li>-->
<!--        <li class="w115">스포츠북</li>-->
<!--        <li class="w430 textleft" @click.prevent="onClickRead()"><a href="#" >스포츠북 문의</a></li>-->
<!--        <li class="w140">2021.06.29 15:36</li>-->
<!--        <li class="w115"><span class="nbicon wgrbg">삭제</span></li>-->
<!--      </ul>-->
<!--      <ul class="boardw">-->
<!--        <li class="w115"><span class="ocmsg openmsg"></span></li>-->
<!--        <li class="w115"><span class="blc nbicon wtblbg">답변완료</span></li>-->
<!--        <li class="w115">스포츠북</li>-->
<!--        <li class="w430 textleft"><a href="cscenterread.html">스포츠북 문의</a></li>-->
<!--        <li class="w140">2021.06.29 15:36</li>-->
<!--        <li class="w115"><span class="nbicon wgrbg">삭제</span></li>-->
<!--      </ul>-->
<!--      <ul class="boardw">-->
<!--        <li class="w115"><span class="ocmsg openmsg"></span></li>-->
<!--        <li class="w115"><span class="blc nbicon wtblbg">답변완료</span></li>-->
<!--        <li class="w115">스포츠북</li>-->
<!--        <li class="w430 textleft"><a href="cscenterread.html">스포츠북 문의</a></li>-->
<!--        <li class="w140">2021.06.29 15:36</li>-->
<!--        <li class="w115"><span class="nbicon wgrbg">삭제</span></li>-->
<!--      </ul>-->
<!--      <ul class="boardw">-->
<!--        <li class="w115"><span class="ocmsg openmsg"></span></li>-->
<!--        <li class="w115"><span class="blc nbicon wtblbg">답변완료</span></li>-->
<!--        <li class="w115">스포츠북</li>-->
<!--        <li class="w430 textleft"><a href="cscenterread.html">스포츠북 문의</a></li>-->
<!--        <li class="w140">2021.06.29 15:36</li>-->
<!--        <li class="w115"><span class="nbicon wgrbg">삭제</span></li>-->
<!--      </ul>-->
    </div>

    <div class="boardbtn">
      <ui-button :link="'qnaWrite'" :className="'grsbtn right ml5'" :text="$t('front.common.qna')" @click="onClickWrite"/>
      <ui-button :link="'accountQna'" :className="'orsbtn right'" :text="$t('front.board.bankAccountQna')" @click="getAcc"/>
    </div>

    <pagination :pageNum="pageInfo.page"
                :pageSize="pageInfo.count_per_list"
                :totalCount="pageInfo.tatal_list_count"
                @goToPage="loadList()"/>

    <board-filter @onSearch="onSearch"/>

  </div>

</template>

<script>
import Pagination from '@/components/ui/Pagination'
import UiButton from '@/components/ui/UiButton'
import BoardFilter from '@/components/common/BoardFilter'
// import { getAccount } from '@/api/cash'
import { mapState } from 'vuex'

export default {
  name: 'qna',
  components: {
    BoardFilter,
    UiButton,
    Pagination
  },
  data () {
    return {
      list: null,
      boardType: 'faq',
      searchParam: {
        searchOption: '',
        searchValue: ''
      },
      idxList: [],
      category: ''
    }
  },
  async created () {
    await this.loadList()
  },
  computed: {
    ...mapState([
      'userData'
    ])
  },
  methods: {
    async getAcc () {
      const title = '계좌문의입니다.'
      const content = '계좌문의입니다.'
      const params = {
        title: title,
        content: content,
        boardType: 'faq',
        category: '',
        faqType: 'bankaccount'
      }

      await this.setSaveAccountBoard(params, async function () {
        await this.loadList()
      }.bind(this))
    },
    onCategoryChange (category) {
      this.category = category
      this.loadList()
    },
    onSearch (value) {
      this.searchParam.searchOption = value.type
      this.searchParam.searchValue = value.text

      this.loadList()
    },
    async loadList () {
      const params = {
        count_per_list: 100,
        boardType: this.boardType,
        category: this.category,
        memId: this.userData.memId
      }

      const searchValue = this.searchParam.searchValue
      if (searchValue) {
        params.searchOption = this.searchParam.searchOption
        params.searchValue = this.searchParam.searchValue
      }

      const result = await this.getBoardList(params, this.pageInfo.page)
      console.log(result)
      result.list.forEach(item => {
        item.isContentOpen = false
      })
      this.list = result.list
      this.idxList = result.idxList
    },
    onClickRead (id) {
      if (!id) id = 1
      this.$router.push({ name: 'qnaRead', params: { boardIdx: id } })
    },
    onClickWrite () {
      this.$router.push({ name: 'qnaWrite' })
    },
    onClickRemove (boardIdx) {
      if (boardIdx) {
        const params = {
          boardIdx: boardIdx,
          boardType: this.boardType,
          delYn: 'Y'
        }

        this.setSaveBoard(params, 'delete', () => {
          this.loadList()
        })
      }
    }
  }
}
</script>
<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
